import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';
import HubSpotForm from '../Components/HubSpotForm';
import homePageVideo from '../Assets/homePageVideoB.mp4';
import globeImage from '../Assets/squareOrangeGlobe.png';
import riskImage from '../Assets/squareRiskManagement.png';
import aiImage from '../Assets/squareCyberai.png';
import magnifyingGlassStrip from '../Assets/magnifyingGlassStrip.png';
import riskManagementImage from '../Assets/rectangleRiskManagement.jpg';
import complianceImage from '../Assets/rectangleCompliance.jpg';


function HomePage() {
  return (
    <div className="home-page">
        <div className="video-container">
            <video 
            src={homePageVideo} 
            autoPlay 
            loop 
            muted 
            playsInline 
            className="background-video"
            />
        </div>
        <div className="image-strip">
            <div className="image-block">
                <Link to="/services" className="image-title">
                    <img src={globeImage} alt="vCISO Services" className="strip-image" />
                    <h3>vCISO Services</h3>
                </Link>
                <p className="image-description">
                    Offering experienced cybersecurity leadership on a flexible basis 
                    across North America & Europe
                </p>
            </div>
            <div className="image-block">
                <Link to="/services" className="image-title">
                    <img src={riskImage} alt="GRC Leadership" className="strip-image" />
                    <h3>GRC Leadership</h3>
                </Link>
                <p className="image-description">
                    Reducing the pain of regulatory requirements, governance, risk & compliance needs
                </p>
            </div>
            <div className="image-block">
                <Link to="/services" className="image-title">
                    <img src={aiImage} alt="CyberAI Consulting" className="strip-image" />
                    <h3>CyberAI Consulting</h3>
                </Link>
                <p className="image-description">
                    Streamlining operational processes with the latest technology in artificial intelligence
                </p>
            </div>
        </div>
        <div className="beige-stripe-block">
            <Link to="/about">
                <img src={riskManagementImage} alt="Kanji Meaning" className="homepage-stripe-image" />
            </Link>
            <div className="homepage-stripe-text">
                <h3>What does "Kanji" mean?</h3>
                <p>
                    The Bushido Code is "the way of the Japanese warrior" and embodies a set of values and 
                    principles that guided the samurai in their conduct, both in battle and in daily life. 
                    Here at Kanji Security, we uphold the same seven core values...
                </p>
                <Link to="/about" className="learn-more-link">Learn more >></Link>
            </div>
        </div>
        <div className="white-stripe-block">
            <Link to="/services">
                <img src={complianceImage} alt="Program Design & Development" className="homepage-stripe-image-left" />
            </Link>
            <div className="homepage-stripe-text-right">
                <h3>Program Design and Development</h3>
                <p>
                    Building customized, scalable cybersecurity programs aligned with business goals, 
                    ensuring data protection, privacy and regulatory compliance
                </p>
                <button 
                    className="homepage-demo-button" 
                    onClick={() => window.open('https://meetings-eu1.hubspot.com/william-ochs', '_blank')}
                >
                    BOOK APPOINTMENT
                </button>
            </div>
        </div>
        <div className="global-solutions-container">
            <img src={magnifyingGlassStrip} alt="Cybersecurity Consulting" className="full-width-image" />
            <div className="overlay-text">
                <h2>CYBERSECURITY CONSULTING</h2>
                <p>Designed and Built for Your Needs</p>
            </div>
        </div>
        <HubSpotForm />
        <div className="map-container">
            <iframe
                title="Cyprus Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d104338.26046784876!2d33.3974022!3d35.19225255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de1767ca494d55%3A0x5b5ea49c0311c0a5!2sNicosia!5e0!3m2!1sen!2seg!4v1725900667010!5m2!1sen!2seg"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
            ></iframe>
        </div>
    </div>
  );
}

export default HomePage;